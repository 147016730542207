<template>
    <div v-if="closeAccountModal">
        <div
            class="jquery-modal blocker current"
            :class="closeAccountModal && 'modal-target'"
            @click="closeModalOnOutsideClick"
            ref="modalRef"
        >
            <div id="modal-bulk" class="modal-target2">
                <div class="w-100 box-border pa3" style="background: white">
                    <div class="flex justify-between pa3 box-border-bottom">
                        <div class="f3 b">{{ state.modalTitle }}</div>
                        <div>
                            <router-link to="#" @click.prevent="toggleCloseAccount({ status: false, type: '' })">
                                <img :src="require('@/assets/images/Close-icon.svg')" alt=" " />
                            </router-link>
                        </div>
                    </div>
                    <form class="flex flex-column mv3" @submit.prevent="onSubmit">
                        <div>Select the businesse(s) you will want to affect.</div>
                        <table class="pv3">
                            <thead>
                                <tr>
                                    <th class="tl">Check</th>
                                    <th class="tl" width="90%">Business</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="business in state.businesses" :key="business._id">
                                    <td class="tl"><input type="checkbox" v-model="business.isSelected" /></td>
                                    <td class="tl mr3" :class="business.isSelected ? 'b' : ''">{{ business.name }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="pv2 tl">
                            <label for="password" class="b mb2">Confirm Password</label>
                            <input id="password" type="password" v-model="state.password" class="product-input w-100" />
                        </div>
                        <button :disabled="state.processing || state.password === ''">
                            {{ state.processing ? 'Processing...' : 'Submit' }}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'CloseAccountModal',
    props: {
        closeModal: Function,
    },

    setup(props, { emit }) {
        const store = useStore()
        const modalRef = ref(null)
        const closeAccountModal = computed(() => store.state?.Settings?.closeAccountModal)
        const closeAccountModalType = computed(() => store.state?.Settings?.closeAccountModalType)

        const state = reactive({
            modalTitle: '',
            password: '',
            processing: false,
            hasMultiBusiness: false,
        })

        const setModalTitle = (type) => {
            if (type === 'factory-reset') {
                state.modalTitle = 'Factory Default Reset'
            } else if (type === 'transaction-reset') {
                state.modalTitle = 'Transaction Reset'
            } else if (type === 'close-account') {
                state.modalTitle = 'Close Account'
            }
        }

        watch(
            () => closeAccountModalType.value,
            (newVal) => {
                setModalTitle(newVal)
            }
        )

        const toggleCloseAccount = () => {
            resetForm()
        }

        const closeModalOnOutsideClick = () => {
            props.closeModal()
        }

        const resetForm = () => {
            state.password = ''
            state.businesses = state.businesses.map((business) => {
                business.isSelected = false
                return business
            })

            store.dispatch('Settings/toggleCloseAccount', { status: false, type: '' })
        }

        const onSubmit = () => {
            state.processing = true
            let selectedBusinesses = state.businesses.filter((business) => business.isSelected === true)
            selectedBusinesses = selectedBusinesses.map((business) => {
                return business.org
            })

            if (selectedBusinesses.length === 0) {
                store.dispatch('Alert/setAlert', { message: 'Please select a business', status: false })
                state.processing = false
                return false
            }

            const payload = { password: state.password, businesses: selectedBusinesses }

            if (closeAccountModalType.value === 'factory-reset') {
                store
                    .dispatch('Settings/factoryReset', payload)
                    .then(({ status }) => {
                        if (status) {
                            resetForm()
                        }
                    })
                    .finally(() => (state.processing = false))
            } else if (closeAccountModalType.value === 'transaction-reset') {
                store
                    .dispatch('Settings/transactionReset', payload)
                    .then(({ status }) => {
                        if (status) {
                            resetForm()
                        }
                    })
                    .finally(() => (state.processing = false))
            } else if (closeAccountModalType.value === 'close-account') {
                store
                    .dispatch('Settings/closeAccount', payload)
                    .then(({ status }) => {
                        if (status) {
                            store.dispatch('Settings/getBusinesses')
                            resetForm()
                        }
                    })
                    .finally(() => (state.processing = false))
            }
        }

        onMounted(() => {
            setModalTitle(closeAccountModalType.value)
            store.dispatch('Settings/getBusinesses', true).then((resp) => {
                if (resp.status) {
                    state.hasMultiBusiness = resp.data.length > 1
                    state.businesses = resp.data.map((business) => {
                        business.isSelected = false
                        return business
                    })
                }
            })
        })

        return {
            state,
            onSubmit,
            closeAccountModal,
            closeAccountModalType,
            toggleCloseAccount,
            closeModalOnOutsideClick,
        }
    },
}
</script>

<style scoped></style>
