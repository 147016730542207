<template>
    <settings-wrapper>
        <template v-slot:child>
            <CloseAccountModal :close-modal="toggleCloseAccount({ status: false, type: '' })" />
            <div class="box-border w-60-l w-90 center mt4">
                <div>
                    <div class="box-border-bottom f4 b pa3">Delete / Reset Account</div>
                    <div class="pa3">
                        <div class="flex items-center justify-between pb3 box-border-bottom">
                            <div>
                                <div class="f5 b pb2">Factory Default Reset</div>
                                <div class="lh-copy pr1">
                                    This will wipe all data and return your account to status as new. It is not reversible
                                </div>
                            </div>
                            <button @click="toggleCloseAccount({ status: true, type: 'factory-reset' })" class="btn2 box-border">Reset</button>
                        </div>
                    </div>
                    <div class="pa3">
                        <div class="flex items-center justify-between pb3 box-border-bottom">
                            <div>
                                <div class="f5 b pb2">Transaction Reset</div>
                                <div class="lh-copy pr1">
                                    This will clear all transactions, inventory quantity and reports. However, your products, bank
                                    details and entities details will remain.
                                </div>
                            </div>
                            <button @click="toggleCloseAccount({ status: true, type: 'transaction-reset' })" class="btn2 box-border">Reset</button>
                        </div>
                    </div>
                    <div class="pa3">
                        <div class="flex items-center justify-between pb3 box-border-bottom">
                            <div>
                                <div class="f5 b pb2">Close Account</div>
                                <div class="lh-copy pr1">
                                    This will close your account and delete your profile and company profile from Simplebks. It is
                                    not reversible.
                                </div>
                            </div>
                            <button @click="toggleCloseAccount({ status: true, type: 'close-account' })" class="btn2 box-border">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </settings-wrapper>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'
import CloseAccountModal from '@/components/CloseAccountModal'

export default {
    name: 'ManageAccount',
    components: { SettingsWrapper, CloseAccountModal },

    setup() {
        const store = useStore()

        const initialState = () => ({
            processing: false,
        })
        const state = reactive(initialState())

        const toggleCloseAccount = (payload) => {
            store.dispatch('Settings/toggleCloseAccount', payload)
        }

        onMounted(() => {})

        return {
            state,
            toggleCloseAccount,
        }
    },
}
</script>

<style scoped>
#button-update {
    position: relative;
    padding: 15px 50px;
    margin: 20px;
    /* display: flex; */
    /* justify-content: flex-end; */
}
</style>
